import Vue from "vue";
import { cloneDeep } from "lodash";
import {
  CLEAR,
  DELETE_MODEL,
  REVERT,
  SET_IS_LOADING,
  SET_MODEL,
  SET_UNREGISTER
} from "./mutation-types";

export default {
  [CLEAR](state) {
    state.model = cloneDeep(state.emptyModel);
  },
  [SET_MODEL](state, model) {
    Vue.set(state, "model", cloneDeep(model));
    state.id = state.model.id;
    Vue.set(state, "initialModel", cloneDeep(model));
  },
  [SET_IS_LOADING](state, isLoading) {
    state.isLoading = isLoading;
  },
  [SET_UNREGISTER](state, unregister) {
    state.unregister = unregister;
  },
  [REVERT](state) {
    state.model = cloneDeep(state.initialModel);
  },
  [DELETE_MODEL](state) {
    state.model = null;
  }
};
