import crudMutations from "@/store/templates/b-crud/mutations";

export default {
  ...crudMutations,
  setName(state, name) {
    state.model.name = name;
  },
  setAction(state, action) {
    state.model.action = action;
  },
  setRejectReason(state, rejectReason) {
    state.model.rejectReason = rejectReason;
  },
  setIsActive(state, isActive) {
    state.model.isActive = isActive;
  },
  setIsIgnorable(state, isIgnorable) {
    state.model.isIgnorable = isIgnorable;
  },
  setIsRejectable(state, isRejectable) {
    state.model.isRejectable = isRejectable;
  }
};
